import { POST }             from "../lib/request"
import {convertToSnakeCase} from "../lib/utils"
import {isNothing}          from "../lib/utils"
import { Loading }          from "./contact_us/loading"
import { Form }             from "./contact_us/form"

class ContactUs {

  constructor(html_node, formUrl, messUrl) {
    this.$btn          = $(html_node)
    this.loading       = false
    this.$modal        = $('#modal')
    this.$modalContent = this.$modal.find('.modal-content')
    this.md5           = null
    this.formUrl       = formUrl
    this.messUrl       = messUrl

    this.onClick           = this.onClick.bind(this)
    this.onRequestComplete = this.onRequestComplete.bind(this)
    this.onSubmit          = this.onSubmit.bind(this)
    this.onSubmitComplete  = this.onSubmitComplete.bind(this)

    this.$btn.on('click', this.onClick)
    this.$modal.modal('hide')
  }

  onClick(e) {
    e.preventDefault()
    this.loading = true
    setTimeout(() => POST(this.formUrl).then(this.onRequestComplete), 800)
  }

  onRequestComplete(response) {
    this.loading = false
    if (isNothing(response)) return

    let md5
    if (md5 = dig(response, ['data', 'md5'])) {
      this.md5 = md5
    } else {
      const error = new Error('Server must send md5')
      throw error
    }

    let html
    if (html = dig(response, ['data', 'html'])) {
      this.showModal(html)
    }
  }

  set loading(val) {
    if (val) {
      Loading.showClick(this.$btn.attr('id'))
      this.$btn.css('color', 'transparent')
    } else {
      this.$btn.css('color', '#fff')
      Loading.hide()
    }
  }

  showModal(html_string) {
    this.$modalContent.html(html_string)
    setTimeout(() => this.$modal.modal('show'), 50)
    this.initModalForm()
  }

  initModalForm() {
    this.$submit = this.$modal.find('#button_submit')
    this.$submit.on('click', this.onSubmit)
    Form.new(this.$modal.find('form'), this.md5)
  }

  onSubmit(e) {
    e.preventDefault()
    this.submitting = true
    setTimeout(() => POST(this.messUrl, Form.data).then(this.onSubmitComplete), 500)
  }

  set submitting(val) {
    if (val) {
      Loading.showSubmit(this.$submit.attr('id'))
      this.$submit.css('color', 'transparent')
      Form.submitting = true
    } else {
      this.$submit.css('color', '#fff')
      Loading.hide()
      Form.submitting = false
    }
  }

  onSubmitComplete(response) {
    this.submitting = false
    if (isNothing(response)) return

    let errors
    if (errors = dig(response, ['data', 'errors'])) {
      Form.renderErrors(errors)
    } else {
      this.$modal.modal('hide')
      setTimeout(() => {
        bootoast.toast({
          message: 'Sent',
          type: 'info',
          position: 'right-top'
        })
      }, 400)
    }
  }


  // * entry point
  static new(btns) {
    let btn,
        $btn,
        data,
        formUrl,
        messUrl

    // можно перопределить дефолтные маршруты

    for (let i = 0; i < btns.length; i++) {
      btn     = btns[i]
      $btn    = $(btn)
      data    = { form: $btn.data('form'), mess: $btn.data('mess') }
      formUrl = isNothing(data.form) ? '/fetch_form' : convertToSnakeCase(data.form)
      messUrl = isNothing(data.mess) ? '/message'    : convertToSnakeCase(data.mess)
      new ContactUs(btn, formUrl, messUrl)
    }
  }

}

$(function() {
  $(document).on('turbolinks:load', function () {
    var $btns = $('.meet_us, .write_review')
    if ($btns.length == 0) return

    ContactUs.new($btns)
  })
})