/*
* Allows to write to / read from
*  local storage and cookies simultaneously.
*
* public interface:
*
*   * LocalStore.save(key, val)
*   * LocalStore.save(key, val, 604800)
*   * LocalStore.fetch(key)
*   * LocalStore.delete(key)
*
* */
class LocalStore {

  // public

  static save(key, val, expires_in = 31536000) {
    localStorage.setItem(key, val)
    LocalStore.__set_cookie(key, val, { expires: expires_in })
  }

  static fetch(key) {
    return localStorage.getItem(key) || LocalStore.__get_cookie(key)
  }

  static delete(key) {
    // todo
  }

  // internal

  static __set_cookie(name, value, options) {
    options = options || {}

    let expires = options.expires

    if (typeof expires === "number" && expires) {
      let d = new Date()
      d.setTime(d.getTime() + expires * 1000)
      expires = options.expires = d
    }

    if (expires && expires.toUTCString) {
      options.expires = expires.toUTCString()
    }

    value = encodeURIComponent(value)

    let updatedCookie = name + "=" + value

    for(let propName in options) {
      updatedCookie += "; " + propName
      let propValue = options[propName]

      if (propValue !== true) {
        updatedCookie += "=" + propValue
      }
    }

    document.cookie = updatedCookie
  }

  static __get_cookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ))
    return matches ? decodeURIComponent(matches[1]) : undefined
  }
}

window.LocalStore = LocalStore
