$(function() {

  //Chrome Smooth Scroll
  try {
    $.browserSelector();
    if($("html").hasClass("chrome")) {
      $.smoothScroll();
    }
  } catch(err) {

  };

  $(window).scroll(function(event) {
    if ($(window).scrollTop() > 0) {
      $('header').addClass('scrolled-header')
    } else {
      $('header').removeClass('scrolled-header')
    }
  });

  $(document).on('click', '.index_become_advertiser', function (event) {
    event.preventDefault()
    $('html, body').animate({
      scrollTop: $('#index_advertiser_section').offset().top - 50
    }, 1000, 'easeOutQuint')
  })

  $(document).on('click', '.index_become_affiliate', function (event) {
    event.preventDefault()
    $('html, body').animate({
      scrollTop: $('#index_affiliate_section').offset().top - 50
    }, 1000, 'easeInOutCirc')
  })

  $(document).on('turbolinks:load', function () {
    //SVG Fallback
    if(!Modernizr.svg) {
      $("img[src*='svg']").attr("src", function() {
        return $(this).attr("src").replace(".svg", ".png");
      });
    };

    $("img, a").on("dragstart", function(event) { event.preventDefault(); });

    const is_rtl = $('html').attr('dir') === 'rtl'

    $('.reviews-slider').slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      rtl: is_rtl,
      prevArrow: $('.prev-arrow'),
      nextArrow: $('.next-arrow'),
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    })

    $('.question-header').click(function(event) {
      $(this).toggleClass('active-question');
    });

    $('.burger').click(function(event) {
      $('.mob-nav').toggleClass('mob-nav-active');
    });

    $('.key-btn').click(function(event) {
      $('.mob-buttons').toggleClass('active-mob-buttons');
    });

    $('.filter-btn').click(function(event) {
      $(this).toggleClass('active-filter-btn');
      $('.offers-section .col-lg-3').toggleClass('active-filters');
    });
  })
});