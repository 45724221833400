import './style.scss';

// Поделиться в соц-сетях
class ShareBtn {
  constructor(html_node, url, text, via, title) {
    this.$btn    = $(html_node)
    this.onClick = this.onClick.bind(this)

    this.$btn.on('click', this.onClick)
    this.$btn.popover({
      title:     "&nbsp;",
      content:   ShareBtn.markup(url, text, via, title),
      html:      true,
      placement: "bottom"
    });
  }

  onClick(e) {
    e.preventDefault()
  }

  // * entry point
  static new(btns) {
    let btn,
        $btn,
        url, text, via, title

    for (let i = 0; i < btns.length; i++) {
      btn   = btns[i]
      $btn  = $(btn)
      url   = $btn.data('url')
      text  = $btn.data('text')
      via   = $btn.data('via')
      title = $btn.data('title')

      new ShareBtn(btn, url, text, via, title)
    }
  }

  static markup(url, text, via, title) {
    // https://habr.com/ru/post/250021/
    // const url = 'https://paysale.net' //window.location.href
    // const text = 'Сопроводительный текст'
    // const via = 'https://twitter.com/PaysaleNET'
    // const title = 'Название программы'

    return `
      <script async src="https://telegram.org/js/telegram-widget.js?14" data-telegram-share-url="${url}" data-comment="${title}" data-size="large"></script>      
      
      <a class='ShareBtn twitter' href="http://twitter.com/share?url=${escape(url)}&text=${title}&via=${via}" target="_blank">
        <i class="icon-twitter"></i>
        <span>Share</span>
      </a>
      
      <a class='ShareBtn vk' href="http://vk.com/share.php?url=${escape(url)}&description=${text}&title=${title}" target="_blank">
        <i class="icon-vkontakte"></i>
        <span>Share</span>
      </a>
      
      <a class='ShareBtn linkedin' href="http://www.linkedin.com/shareArticle?url=${escape(url)}" target="_blank">
        <i class="icon-linkedin"></i>
        <span>Share</span>
      </a>
    `
  }
}

$(function() {
  // $(document).on("turbolinks:before-cache", function () {
  //
  // })

  $(document).on('turbolinks:load', function () {
    var $btns = $('.share-btn')
    if ($btns.length == 0) return

    ShareBtn.new($btns)
  })
})