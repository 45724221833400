// Клик по чекбоксу "dating" включит чекбоксы подкатегорий "dating"
// Если выключить все чекбоксы подкатегорий - выключит чекбокс "dating"

import * as categories from './categories'

class FilterDatingSubChecker {
  // * entry point
  static new($cbs) {
    for (let i = 0; i < $cbs.length; i++) {
      new FilterDatingSubChecker($cbs[i])
    }
  }

  constructor(html_node_cb) {
    this.$cb_main      = $(html_node_cb)
    this.sub_cbs       = []
    this.onClickMainCb = this.onClickMainCb.bind(this)
    this.onClickSubCb  = this.onClickSubCb.bind(this)


    this.collect_sub_cbs()
    this.$cb_main.on('click', this.onClickMainCb)
  }

  collect_sub_cbs() {
    let category_id
    let $cb

    for(let i = 0; i < categories.ALL_SUB.length; i++) {
      category_id = categories.ALL_SUB[i]
      $cb         = $(`input[type="checkbox"][value="${category_id}"]`)
      this.sub_cbs.push($cb)
      $cb.on('click', this.onClickSubCb)
    }
  }

  click_sub_cbs(value) {
    let $cb
    for(let i = 0; i < this.sub_cbs.length; i++) {
      $cb = this.sub_cbs[i]
      $cb.prop('checked', value)
    }
  }

  onClickMainCb() {
    const checked = this.$cb_main.prop('checked')
    this.click_sub_cbs(checked)
  }

  onClickSubCb() {
    let checks = []
    let $cb
    for(let i = 0; i < this.sub_cbs.length; i++) {
      $cb = this.sub_cbs[i]
      checks.push($cb.prop('checked'))
    }

    let any_sub = false
    for(let j = 0; j < checks.length; j++) {
      if (checks[j]) {
        any_sub = true
        break
      }
    }

    this.$cb_main.prop('checked', any_sub)
  }
}

$(function() {
  $(document).on('turbolinks:load', function () {
    var $cbs = $(`input[type="checkbox"][value="${categories.DATING}"]`)
    if ($cbs.length == 0) return

    FilterDatingSubChecker.new($cbs)
  })
})