var fLazyStart = function ($target) {
    var $t = $target == undefined ? $(".lazy img") : $target

    $t.lazyload({
        effect:       "fadeIn",
        threshold:   50
    });

    setTimeout(function() {
      $(".lazy img").css('opacity', 1)
    }, 500)

    setTimeout(function () {
        $(window).scroll();
    },500);
};

$(function() {
  $(document).on("turbolinks:before-cache", function () {
    $(".lazy img").css('opacity', 0)
  })

    $(document).on('turbolinks:load', function () {
        fLazyStart();
    })
});

