window.csrf = document.querySelector('[name="csrf-token"]').content

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  // console.log("<parseJSON>");
  if (response.status === 204) {                                                                                        // 204 - No Content
    return { status: response.status };
  } else {
    return response.json().then(data => ({ data, status: response.status }));
  }
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  // console.log("<checkStatus>");
  if (response.status >= 200 && response.status < 300 || response.status === 400) {
    return response;
  }

  const error = new Error(response.statusText);
  // noinspection JSUndefinedPropertyAssignment
  error.response = response;
  throw error;
}

/**
 * @param {Error} error
 */
function catchErr({ message }) {
  bootoast.toast({
    message: message,
    type: 'danger',
    position: 'right-top'
  });
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} fullPath  The path we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           An object containing either "data" or "err"
 */
function request(fullPath, options) {
  // noinspection JSCheckFunctionSignatures
  return fetch(fullPath, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(({ data, status }) => ({ data, status }))
    .catch(catchErr)
}

export function POST(requestURL, data) {
  // console.log('<API.post> data = ');
  // console.log(data);

  return request(requestURL, {
    method:                 'POST',
    credentials:            'same-origin',
    headers: {
      'X-CSRF-Token':       window.csrf || '',
      'X-Requested-With':   'XMLHttpRequest',
      'Accept':             'application/json',
      'Content-Type':       'application/json',
    },
    body: JSON.stringify(data)
  });
}