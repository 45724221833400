  $(document).ready(function () {
    $(document).on('turbolinks:load', function () {

      var readed = LocalStore.fetch('cookies-readed')
      if (!readed) {
        $('.cookies').removeClass('hidden');
      }
      $('#cookies-agree').click(function (e) {
        e.preventDefault();
        LocalStore.save('cookies-readed', true);
        $('.cookies').addClass('hidden');
      })

    })
  });