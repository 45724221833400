import {Loading} from "../contact_us/loading"

// Применит Spy-Affix-Loading animation к кнопке "Find" фильтра
class FilterSubmitAffix {

  // * entry point
  static new($btns) {
    for (let i = 0; i < $btns.length; i++) {
      new FilterSubmitAffix($btns[i])
    }
  }

  constructor(html_node_btn) {
    this.$btn  = $(html_node_btn)
    this.$form = $('form#filter_offers_form')

    this.invalidateButtonWidth = this.invalidateButtonWidth.bind(this)
    this.onClick               = this.onClick.bind(this)

    this.initAffix()
    this.initButtonPosition()
    setInterval(this.invalidateButtonWidth, 1000)
    this.initClickAnimation()
  }

  // ждём, пока кнопка примет ширину, потом показываем её
  initButtonPosition() {
    this.invalidateButtonWidth()
    const that = this
    setTimeout(function() {
      that.$btn.css('opacity', '1')
    }, 300)
  }

  // Адаптация ширины affix кнопки под mobile,tablet ширину экрана
  invalidateButtonWidth() {
    const w = this.$form.outerWidth()
    if (w <= 10) {
      this.$btn.css('opacity', '0')
      this.$btn.css('width', `${w}px`)
    } else {
      this.$btn.css('width', `${w}px`)
      const that = this
      setTimeout(function() {
        that.$btn.css('opacity', '1')
      }, 300)
    }
  }

  initAffix() {
    $('body').scrollspy({
      target: this.$btn.attr('id'),
    })

    const that = this
    this.$btn.affix({
      offset: {
        top: function () { return this.top = that.$form.outerHeight() + that.$form.position().top - $(window).height() + 200 }
      }
    })
  }

  initClickAnimation() {
    this.$btn.bind('click', this.onClick)
  }

  onClick() {
    // Loading.showSubmit портит affix стиль, его зафиксируем так
    const style = this.$btn.attr('style')

    Loading.showSubmit(this.$btn.attr('id'))
    this.$btn.attr('style', style)
    this.$btn.css('color', 'transparent')

    this.$form.submit()
  }
}

$(function() {
  $(document).on('turbolinks:load', function () {
    var $btns = $('.input#filter_offers')
    if ($btns.length == 0) return

    FilterSubmitAffix.new($btns)
  })
})