(function () {
  "use strict"

  $(document).on("turbolinks:before-cache", function () {
    try {
      $('.select2').select2('destroy')
    } catch(e) {

    }
  })

  $(document).on('turbolinks:load', function () {
    $('.select2').select2()
  })

}())
