// Is a given value equal to null?
export const isNull = function(obj) {
  return obj === null;
};

// Is a given variable undefined?
export const isUndefined = function(obj) {
  return obj === void 0;
};

export const isNothing = function(obj) {
  return isNull(obj) || isUndefined(obj);
};

/**
 * Converts a snake_cased string to camelCase.
 * Leading and trailing underscores are ignored.
 *
 * @param {String} string The snake_cased string to convert
 * @returns {String} A camelCased version of the string
 *
 * @example
 *
 * // returns "aSnakeCasedString"
 * convertToCamelCase('a_snake_cased_string')
 *
 * // returns "_leadingUnderscore"
 * convertToCamelCase('_leading_underscore')
 *
 * // returns "trailingUnderscore_"
 * convertToCamelCase('trailing_underscore_')
 */
export const convertToCamelCase = (string) =>
    string.replace(/([a-z0-9])_([a-z0-9])/gi, (match, p1, p2) => `${p1}${p2.toUpperCase()}`);

/**
 * Converts camelCase string to snake_case
 *
 * @param {*} string
 */
export const convertToSnakeCase = (string) =>
    slugifyWithUnderscore((string.match(/([a-zA-Z][^A-Z]*)/g) || [string]).join(' '));

/**
 * Replaces whitespace and non-sluggish characters with underscores
 * @param {String} str
 * @returns {String}
 */
export const slugifyWithUnderscore = (str) => slugify(str, '_');

/**
 * Replaces whitespace and non-sluggish characters with a given separator
 * @param {String} str - The string to slugify
 * @param {String=} separator - The separator used to separate words (defaults to "-")
 * @returns {String}
 */
export const slugify = (str, separator = '-') => {
  const slug = str
      .trim()
      .toLowerCase()
      .replace(/[^a-zA-Z0-9_.-]+/g, separator)
      // Remove any duplicate separators or separator prefixes/suffixes
      .split(separator)
      .filter(Boolean)
      .join(separator);

  return slug === separator ? '' : slug;
};

/*
EXAMPLES
let index = 2;
const data = {
  foo: {
    foz: [1, 2, 3],
    bar: {
      baz: ['a', 'b', 'c']
    }
  }
};

dig(data, ['foo', 'foz', index]);
// => 3

dig(data, ['foo', 'bar', 'baz', 8, 'foz']);
// => null
*/
window.dig = (obj, keys) =>
    keys.reduce(
        (xs, x) => (xs && xs[x] !== null && xs[x] !== undefined ? xs[x] : null),
        obj
    );
