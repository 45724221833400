import {POST}      from "../lib/request";
import {Form}      from "./contact_us/form";
import {Loading}   from "./contact_us/loading";
import {isNothing} from "../lib/utils";
import {inView}    from "../lib/in_view";

class IndexForm {

  // * entry point
  static new($divs) {
    for (let i = 0; i < $divs.length; i++) {
      new IndexForm($divs[i])
    }
  }

  constructor(html_node) {
    this.container  = html_node
    this.$container = $(html_node)
    this.md5        = null
    this.$submit    = null

    this.onSubmit         = this.onSubmit.bind(this)
    this.onSubmitComplete = this.onSubmitComplete.bind(this)
    this.onScroll         = this.onScroll.bind(this)
    this.initFormComplete = this.initFormComplete.bind(this)

    this.init()
  }

  init() {
    $(window).on('scroll', this.onScroll)
  }

  onSubmit(e) {
    e.preventDefault()
    this.submitting = true
    let data = Form.data
    data.response = grecaptcha.getResponse()
    setTimeout(() => POST('/message', data).then(this.onSubmitComplete), 500)
  }

  set submitting(val) {
    if (val) {
      Loading.showSubmit(this.$submit.attr('id'))
      this.$submit.css('color', 'transparent')
      Form.submitting = true
    } else {
      this.$submit.css('color', '#fff')
      Loading.hide()
      Form.submitting = false
    }
  }

  onSubmitComplete(response) {
    this.submitting = false
    if (isNothing(response)) return

    let errors
    if (errors = dig(response, ['data', 'errors'])) {
      Form.renderErrors(errors)
    } else {
      Form.clearInputs()
      setTimeout(() => {
        bootoast.toast({
          message: 'Sent',
          type: 'info',
          position: 'right-top'
        })
      }, 400)
    }
  }

  onScroll() {
    if (inView(this.container)) {
      this.initForm()
    }
  }

  initForm() {
    $(window).off('scroll', this.onScroll)
    POST('/fetch_form', { index: true }).then(this.initFormComplete)
  }

  initFormComplete(response) {
    if (isNothing(response)) return

    let md5
    if (md5 = dig(response, ['data', 'md5'])) {
      this.md5 = md5
    } else {
      const error = new Error('Server must send md5')
      throw error
    }

    let html
    if (html = dig(response, ['data', 'html'])) {
      this.showForm(html)
    }
  }

  showForm(html_string) {
    this.$container.html(html_string)
    this.initFormInputs()
    this.initRecaptcha()
  }

  initFormInputs() {
    this.$submit = this.$container.find('#button_submit')
    this.$submit.on('click', this.onSubmit)
    Form.new(this.$container.find('form'), this.md5)
  }

  initRecaptcha() {
    var captchaWidgetId = grecaptcha.render( 'recaptcha_index_form', {
      sitekey: '6Ledox0UAAAAAHhjWrfck6Enl96yjz56j7DsaqxF',
      theme: 'light'
    })
  }
}

$(function() {
  $(document).on('turbolinks:load', function () {
    var $containers = $('.container#index_form')
    if ($containers.length == 0) return

    IndexForm.new($containers)
  })
})