import { MD5 }         from "../../lib/md5"
import { isNothing }   from "../../lib/utils"
import { isNotObject } from "@rails/webpacker/package/utils/helpers"
import {replaceEmoji}  from "../../lib/emoji_utils"

export class Form {

  static new($form, md5) {
    Form.instance = new Form($form, md5)
  }

  constructor($form, md5) {
    this.md5      = md5
    this.$name    = $form.find('#name')
    this.$company = $form.find('#company')
    this.$email   = $form.find('#email')
    this.$message = $form.find('#message')
    this.inputs   = [this.$name, this.$company, this.$email, this.$message]

    if (isNothing(this.md5)) {
      const error = new Error('Server must send md5')
      throw error
    }

    // Начал исправлять ошибку в поле? Тогда не будем навязывать красную рамку ошибки валидации.
    this.inputs.forEach($input => $input.on('input', () => Form.clearAsErrorField($input)))
  }

  static set submitting(val) {

  }

  static renderErrors(errors) {
    Form.instance.inputs.forEach($input => Form.clearAsErrorField($input))
    if (isNotObject(errors)) return
    Object.keys(errors).forEach(key => Form.markAsErrorField(key))
  }

  static markAsErrorField(name) {
    Form.instance[`$${name}`].addClass('has-error')
  }

  static clearAsErrorField($input) {
    $input.removeClass('has-error')
  }

  static get data() {
    const ins = Form.instance

    let data = {
      name:    ins.$name.val(),
      company: ins.$company.val(),
      email:   ins.$email.val(),
      message: ins.$message.val(),
    }

    return Form.addKey(data)
  }

  // friend of message_key.rb
  static addKey(data) {
    const ins = Form.instance

    let key = [
      ins.md5,
      data.name,
      data.company,
      data.email,
      data.message
    ].join(';')

    key = replaceEmoji(key)
    data['key'] = MD5(key)

    return data
  }

  static clearInputs() {
    Form.instance.inputs.forEach($input => $input.val(''))
  }
}