import {Loading} from "../../js/contact_us/loading"
import "./style.scss"

// Как-бы ссылка, при клике по которой происходит переход на указанный `data-wurl`
class Wurl {
  constructor(html_node, url) {
    this.$btn    = $(html_node)
    this.url     = url
    this.onClick = this.onClick.bind(this)
    this.target  = this.$btn.data('target')

    this.$btn.attr('id', ~~(Math.random() * 1000000))

    this.$btn.on('click', this.onClick)
  }

  onClick(e) {
    if (this.target == "_blank") {
      this.$btn.attr('target', '_blank')
      this.$btn.attr('href', this.url)
    } else {
      e.preventDefault()
      this.submitting = true
      window.location.href = this.url
      const that = this
      setTimeout(function() { that.submitting = false }, 5000)
    }
  }

  set submitting(val) {
    if (val) {
      Loading.showSubmit(this.$btn.attr('id'))
      this.$btn.css('color', 'transparent')
    } else {
      this.$btn.css('color', '#fff')
      Loading.hide()
    }
  }

  // * entry point
  static new(btns) {
    let btn,
        $btn,
        url

    for (let i = 0; i < btns.length; i++) {
      btn  = btns[i]
      $btn = $(btn)
      url  = $btn.data('wurl')

      new Wurl(btn, url)
    }
  }
}

$(function() {
  // $(document).on("turbolinks:before-cache", function () {
  //
  // })

  $(document).on('turbolinks:load', function () {
    var $btns = $('.wurl')
    if ($btns.length == 0) return

    Wurl.new($btns)
  })
})