export class Loading {
  static showClick(id) {
    JsLoadingOverlay.show({
      'overlayBackgroundColor': '#666666',
      'overlayOpacity': 0.0,
      'spinnerIcon': 'ball-spin',
      'spinnerColor': '#fff',
      'spinnerSize': '1x',
      'overlayIDName': 'overlay',
      'spinnerIDName': 'spinner',
      'offsetY': '-15px',
      'containerID': id,
    })
  }

  static hide() {
    JsLoadingOverlay.hide()
  }

  static showSubmit(id) {
    JsLoadingOverlay.show({
      'overlayBackgroundColor': '#666666',
      'overlayOpacity': 0.0,
      'spinnerIcon': 'ball-spin',
      'spinnerColor': '#fff',
      'spinnerSize': '1x',
      'overlayIDName': 'overlay',
      'spinnerIDName': 'spinner',
      'offsetY': '-15px',
      'containerID': id,
    })
  }
}