import {Hilitor} from "../vendor/hilitor"

$(document).ready(function () {
  $(document).on('turbolinks:load', function () {
    var myHilitor = new Hilitor("faqSection");
    // myHilitor.setMatchType("left");
    $('#textSearch').keyup(function () {
      var searchText = $('#textSearch').val().toLowerCase();
      if (searchText.length > 0) {
        myHilitor.apply(searchText);
        $('.toSearch').each(function () {
          var el = $(this);
          if (el.text().toLowerCase().indexOf(searchText) !== -1) {
            if (!el.hasClass('link')) {
              el.parent().parent().find('.question-header').addClass('active-question')
            }
          }
          else {
            if (!el.hasClass('link')) {
              el.parent().parent().find('.question-header').removeClass('active-question')
            }
          }
        });
      }
      else {
        myHilitor.remove();
        $('.question-header').removeClass('active-question')
      }
    });
    $('.question-header').removeClass('active-question')
    // location.hash && $('#collapse' + location.hash.replace('#', '') + '.collapse').collapse('show');
  })
});